import React, { Component } from 'react';
import '../../scss/main.scss';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

//UIkit.notification('Hello App.');

const Board = (props) => {
  return (
    <div id="board">
      <div className="uk-padding-small uk-margin-small"> {props.children}</div>
    </div>
  );
};
export default Board;
