import React from 'react';
import GalleryItemMobile from './Gallery-Item-mobile';

import data from '../../../data/products.json';
const GalleryMobile = () => {
  return (
    <div
      className="uk-position-relative uk-visible-toggle uk-light uk-hidden@s"
      tabIndex="-1"
      data-uk-slider
    >
      <ul className="uk-slider-items uk-grid">
        {data.gallery.items.map((item) => {
          return (
            <GalleryItemMobile
              price={item.price}
              img={item.image.src}
              alt={item.image.alt}
              text={item.text}
            />
          );
        })}
      </ul>

      <a
        className="uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        data-uk-slidenav-previous=""
        uk-slider-item="previous"
      ></a>
      <a
        className="uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        data-uk-slidenav-next=""
        uk-slider-item="next"
      ></a>
    </div>
  );
};

export default GalleryMobile;
