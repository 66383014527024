import React, { Component } from 'react';
import { getWeekDay } from './Header.helper';
import HeaderSvg1 from '../../../img/icons/HotWings_fire.svg';
import HeaderImage1 from '../../../img/special/HotWings_solo.png';

import HeaderSvg2 from '../../../img/icons/Chickendrums_left.svg';
import HeaderImage2 from '../../../img/special/Haehnchen_normal.png';

class HeaderStopper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekDay: getWeekDay(new Date()),
    };
  }

  render() {
    if (this.state.weekDay === 'Dienstag') {
      return (
        <div className="uk-width-medium@s uk-card uk-card-secondary special special--stopper">
          <img
            src={HeaderSvg1}
            alt="Fire Icon"
            width="100"
            className="svg--right"
          />
          <div className="uk-card-media-top">
            <h3 className="uk-text-center">Nur heute!</h3>
            <img
              src={HeaderImage1}
              alt="Hot Wings Solo"
              className="uk-visible@s"
            />
            <p className="">
              nur
              <br />
              4,00 &euro;
            </p>
          </div>
          <div className="uk-card-body clip-left">
            <h3 className="uk-card-title">
              <span>6</span> Hot Wings
            </h3>
          </div>
        </div>
      );
    } else if (this.state.weekDay === 'Donnerstag') {
      return (
        <div className="uk-width-medium@s uk-card uk-card-secondary special special--stopper">
          <img
            src={HeaderSvg2}
            alt=""
            data-uk-svg
            width="100"
            className="svg--left"
          />
          <div className="uk-card-media-top">
            <h3 className="uk-text-center">Nur heute!</h3>
            <img
              src={HeaderImage2}
              alt="Hähnchenteile "
              className="uk-visible@s"
            />
            <p className="small">
              3 Teile - &nbsp;6,00 &euro; <br />6 Teile - 12,00 &euro; <br />9
              Teile - 17,50 &euro;
            </p>
          </div>
          <div className="uk-card-body clip-left">
            <h3 className="uk-card-title uk-text-right">Hähnchen-Teile</h3>
          </div>
        </div>
      );
    } else {
      return (
        <p className="uk-padding-small uk-text-bold uk-text-center special--alternative">
          {/*  Heute ist erst {this.state.weekDay}. Aber bald gibt es wieder
          Angebote! */}
        </p>
      );
    }
  }
}

export default HeaderStopper;
