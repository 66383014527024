import React from 'react';

const MavMobile = () => {
  return (
    <div
      id="sidenav"
      data-uk-offcanvas="overlay: true"
      className="uk-offcanvas"
    >
      <div className="uk-offcanvas-bar">
        <ul className="uk-nav uk-nav-default">
          <li>
            <a href="#menu" data-uk-scroll>
              Speisekarte
            </a>
          </li>
          <li>
            <a href="#contact" data-uk-scroll>
              Anfahrt & Kontakt
            </a>
          </li>
          <li className="uk-nav-divider"></li>
          <li>
            <span>
              Öffnungszeiten:
              <br />
              Mo.-So. 11-20 Uhr
            </span>
          </li>
          <li className="uk-nav-divider"></li>
          <li>
            <span>
              Telefonnummer:
              <br />
              <a href="callto:06033747733" title="Rufen Sie uns an!">
                06033 747733
              </a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MavMobile;
