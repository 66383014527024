import React, { Component } from 'react';
import logoText from '../../img/logo/Schrift_bg/SVG/Logo-Schrift-bg.svg';
import NavOpening from './Nav-opening';
import NavMobile from './Nav-mobile';

export default class Nav extends Component {
  render() {
    return (
      <>
        <nav
          className="uk-navbar uk-navbar-container"
          id="main-nav"
          data-uk-navbar=""
          data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top;"
        >
          <div className="uk-navbar-left">
            <a
              className="uk-navbar-toggle uk-hidden@s"
              href="#"
              data-uk-toggle="target: #sidenav"
            >
              <span data-uk-navbar-toggle-icon className="width-20"></span>
              <span className="uk-margin-small-left">Menu</span>
            </a>
            <a
              href="#"
              className="uk-navbar-item uk-logo uk-visible@s"
              data-uk-scroll
            >
              <img
                src={logoText}
                height="80"
                alt="AFC Logo"
                className="nav-logo"
                data-uk-svg=""
              />
            </a>

            <ul className="uk-navbar-nav uk-visible@s uk-navbar-right">
              <li>
                <a href="#menu" data-uk-scroll="">
                  Speisekarte
                </a>
              </li>
              {/*   <li>
                <a href="#service" data-uk-scroll="">
                  Service
                </a>
              </li> */}
              <li>
                <a href="#contact" data-uk-scroll="">
                  Anfahrt & Kontakt
                </a>
              </li>
            </ul>
          </div>
          <div className="uk-navbar-right">
            <a
              href="#"
              className="uk-navbar-item uk-logo uk-hidden@s"
              data-uk-scroll
            >
              <img
                src={logoText}
                height="45"
                alt="AFC Logo"
                className="nav-logo"
                data-uk-svg=""
              />
            </a>
            <NavOpening />
          </div>
        </nav>
        <NavMobile />
      </>
    );
  }
}
