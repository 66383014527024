import React, { Component } from 'react';

class NavOpening extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: new Date().getHours(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 100000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      hour: new Date().getHours(),
    });
  }
  render() {
    console.log(this.state.hour);
    if (this.state.hour >= 11 && this.state.hour <= 19) {
      return (
        <>
          <div className="uk-text-center uk-margin-right">
            <p className="uk-text-center uk-text-bold nav-opening">
              Wir haben bis 20 Uhr geöffnet.
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="uk-text-center uk-margin-right">
            <span className="uk-text-center uk-text-bold">
              Wir haben leider geschlossen.
            </span>
            <br></br>
            <span className="uk-text-center uk-text-bold">
              Wir öffnen wieder um 11 Uhr.
            </span>
          </div>
        </>
      );
    }
  }
}

export default NavOpening;
