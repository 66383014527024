import React from 'react';
import Board from '../layout/board';
import SpecialSlider from '../sections/Special/SpecialSlider';
import Special from '../sections/Special/Special';

const BoardFour = () => {
  return (
    <Board>
      <div>
        <h3 id="specialOffer" className="menu-title">
          Unsere Angebote
        </h3>
        <div className="uk-container uk-container-xlarge">
          {/* <div className="uk-flex-center uk-child-width-1-2@s uk-grid" uk-grid>
            <div>
              <SpecialSlider />
            </div>
          </div> */}
          <div className="uk-flex-center  uk-grid" uk-grid>
            <div>
              <Special />
            </div>
          </div>
        </div>
      </div>
    </Board>
  );
};

export default BoardFour;
