import React from 'react';
import Board from '../layout/board';
import OrderSides from '../sections/Order/Order-sides';

const BoardOne = () => {
  return (
    <Board>
      <div>
        <h3 id="bdd" className="menu-title">
          Beilagen | Drinks
        </h3>
        <OrderSides />
        <h4 className="subheadline uk-text-center">
          Die leckersten Hähnchen weit und breit
        </h4>
      </div>
    </Board>
  );
};

export default BoardOne;
