import React from 'react';

import data from '../../../data/products.json';

/* fetch('./data.json')
  .then((response) => {
    console.log(response);
    return response.json();
  })
  .then((data) => {
    // Work with JSON data here
    console.log(data);
  })
  .catch((err) => {
    // Do something for an error here
    console.log('Error Reading data ' + err);
  }); */

const OrderSides = () => {
  return (
    <div
      className="uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s"
      data-uk-grid
      data-uk-scrollspy="target: .sides; cls: uk-animation-fade; delay: 500"
    >
      {data.orderSides.cols.map((col) => {
        return (
          <div>
            <div className="uk-card order sides">
              {col.image && (
                <div className="uk-card-media-top">
                  <img src={col.image.src} alt={col.image.alt} data-uk-img />
                </div>
              )}

              <div className="uk-card-footer">
                {col.content.map((side) => {
                  return (
                    <>
                      {side.price && (
                        <>
                          <div className="uk-grid-small" data-uk-grid>
                            <div className="uk-width-expand">
                              <h4>
                                {side.name}
                                {side.extra && (
                                  <span className="order__extra">
                                    {side.extra}
                                  </span>
                                )}
                              </h4>
                            </div>
                            <div className="order__price">
                              {side.price} &euro;
                            </div>
                          </div>
                        </>
                      )}
                      {side.options && (
                        <>
                          <div className="uk-grid-small" data-uk-grid>
                            <h4>
                              {side.name}
                              {side.extra && (
                                <span className="order__extra">
                                  {side.extra}
                                </span>
                              )}
                            </h4>
                          </div>

                          {side.options.map((option) => {
                            return (
                              <div className="uk-grid-collapse" data-uk-grid>
                                <div className="uk-width-expand order__size order__size--small">
                                  {option.size}
                                </div>
                                <div className="order__price">
                                  {option.price} &euro;
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderSides;
