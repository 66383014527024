import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/scrollToTop';
import DataProtection from './components/pages/data-protection';
import Imprint from './components/pages/imprint';
import Home from './components/pages/home';
import Boards from './components/Boards';

//USE THIS FOR BOARDS BUILD!!
//change homepage to:   "homepage": "https://afc-butzbach.de/boards/", to package.json
/* import Boards from './components/Boards';
ReactDOM.render(<Boards />, document.querySelector('#root')); */

//USE THIS FOR HOME TESTING BUILD!!
//change homepage to: "homepage": "https://afc-butzbach.de/testing/", to package.json
/* ReactDOM.render(
  <Router basename={'testing'}>
    <ScrollToTop />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/imprint" component={Imprint} />
      <Route path="/data-protection" component={DataProtection} />
      <Route path="/boards" component={Boards} />
    </Switch>
  </Router>,
  document.querySelector('#root')
); */

//USE THIS FOR HOME BUILD!!
//change homepage to: "homepage": "./", to package.json
ReactDOM.render(
  <Router>
    <ScrollToTop />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/imprint" component={Imprint} />
      <Route path="/data-protection" component={DataProtection} />
      <Route path="/boards" component={Boards} />
    </Switch>
  </Router>,
  document.querySelector('#root')
);
