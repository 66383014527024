import React from 'react';
import PDFAllergene from '../../../pdf/AFC_Allergene_infos.pdf';
import OrderMenu from './Order-menu';
import OrderChicken from './Order-chicken';
import OrderSides from './Order-sides';

const Order = () => {
  return (
    <div>
      {' '}
      <section className="uk-section uk-container" id="menu">
        <h2>Speisekarte</h2>
        <div className="sticky-width uk-width-1-6@m"></div>
        <div
          className="menu__nav uk-card uk-card-body uk-card-primary uk-width-1-6@m uk-hidden@s"
          data-uk-sticky="start: #menu; offset: 80px; bottom: true; show-on-up: true; animation: uk-animation-slide-top; width-element:.sticky-width"
        >
          <h3 className="uk-card-title">Unsere Auswahl</h3>
          <ul
            className="uk-nav uk-nav-default"
            uk-scrollspy-nav="closest: li; scroll: true"
          >
            <li>
              <a href="#menues">Menüs</a>
            </li>
            <li>
              <a href="#chicken">Chicken</a>
            </li>
            <li>
              <a href="#bdd">Beilagen, Drinks, Desserts</a>
            </li>
          </ul>
        </div>
        <div className="menu__nav uk-card uk-card-body uk-card-primary uk-width-1-4@m uk-visible@s">
          <h3 className="uk-card-title">Unsere Auswahl</h3>
          <ul
            className="uk-nav uk-nav-default"
            uk-scrollspy-nav="closest: li; scroll: true"
          >
            <li>
              <a href="#menues">Menüs</a>
            </li>
            <li>
              <a href="#chicken">Chicken</a>
            </li>
            <li>
              <a href="#bdd">Beilagen, Drinks, Desserts</a>
            </li>
          </ul>
        </div>
        <div>
          <h3 id="menues" className="menu-title">
            Menüs
          </h3>
          <OrderMenu />
          <h3 id="chicken" className="menu-title">
            Chicken
          </h3>
          <OrderChicken />
          <h3 id="bdd" className="menu-title">
            Beilagen | Drinks
          </h3>
          <OrderSides />
          <a href="#menu" data-uk-scroll className="btn-toTop uk-padding">
            Top
          </a>
          <hr className="uk-divider-icon" />
          <div className="uk-flex uk-flex-center">
            <a
              href={PDFAllergene}
              className="uk-button uk-button-primary uk-button-large btn-text uk-width-1-3@s"
              title="Allergene als PDF"
            >
              PDF Allergene
            </a>
            <a
              href="callto:06033747733"
              className="uk-button uk-button-primary uk-button-large btn-text uk-margin-left uk-width-1-3@s"
              title="Einfach anrufen"
            >
              Anrufen
            </a>
          </div>
          <div className="uk-flex uk-flex-center">
            <h3 className="uk-heading-medium uk-margin-top uk-margin-bottom attention-headline uk-text-center">
              Es ist nur Barzahlung möglich!
            </h3>
          </div>
        </div>
        <div
          className="uk-flex uk-flex-between uk-child-width-auto uk-padding-large "
          data-uk-grid
        >
          <p className="uk-text-small">
            *ohne Konservierungsstoffe <br></br>
            **Alle Hähnchenprodukte sind halal.
          </p>

          <p className="uk-text-small">
            "French Fries":&nbsp;
            <a href="http://www.freepik.com">
              Designed by lifeforstock / Freepik
            </a>
          </p>
        </div>
        <a
          href="#menu"
          data-uk-scroll
          className="btn-toTop high-zindex uk-padding"
        >
          Top
        </a>
      </section>
    </div>
  );
};

export default Order;
