import React from 'react';
import GalleryItem from './Gallery-Item';
import GalleryMobile from './Gallery-mobile';

import data from '../../../data/products.json';

const Gallery = () => {
  return (
    <>
      <section className="uk-section uk-container" id="gallery">
        <h2>Unsere Highlights</h2>

        <div
          className="uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-3@l uk-visible@s"
          data-uk-grid
        >
          {data.gallery.items.map((item) => {
            return (
              <GalleryItem
                price={item.price}
                img={item.image.src}
                alt={item.image.alt}
                text={item.text}
              />
            );
          })}
        </div>
        <GalleryMobile />
      </section>
    </>
  );
};

export default Gallery;
