import React, { Component } from 'react';
import Nav from '../Nav/Nav';
import Header from '../sections/Header/Header';
import Gallery from '../sections/Gallery/Gallery';
import Special from '../sections/Special/Special';
import Order from '../sections/Order/Order';
import Service from '../sections/Service/Service';
import Contact from '../sections/Contact/Contact';
import Footer from '../Footer/Footer';
import '../../scss/main.scss';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

//UIkit.notification('Hello App.');

export default class Home extends Component {
  render() {
    return (
      <>
        <Nav />
        <Header />
        <main id="main">
          <Gallery />
          <Special />
          <Order />
          <Contact />
        </main>
        <Footer />
      </>
    );
  }
}
