import React from 'react';

const GalleryItem = (props) => {
  return (
    <div>
      <div className="highlight uk-inline">
        <div className="badge polygon polygon--prize">
          <div className="polygon__aligner">
            <div>
              <span className="small">ab</span>
              <br />
              {props.price} &euro;
            </div>
          </div>
        </div>
        <img src={props.img} alt={props.alt} />
        <div className="uk-overlay uk-position-bottom uk-overlay-primary">
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
};

export default GalleryItem;
