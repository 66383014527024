import React from 'react';

import data from '../../../data/products.json';

const OrderChicken = () => {
  return (
    <div
      className="chicken-grid uk-child-width-expand@l uk-child-width-1-3@m uk-child-width-1-2@s uk-flex-center"
      data-uk-grid
      data-uk-scrollspy="target: .chicken; cls: uk-animation-fade; delay: 500"
    >
      {data.orderChicken.cols.map((chicken) => {
        return (
          <div>
            <div className="uk-card order chicken">
              <div className="uk-card-media-top">
                <img
                  src={chicken.image.src}
                  alt={chicken.image.alt}
                  data-uk-img
                />
              </div>
              {chicken.contents.map((content, index) => {
                return (
                  <>
                    <div className={`uk-card-body ${index > 0 && 'subbody'}`}>
                      <h3 className="uk-card-title">{content.name}</h3>
                      {content.extra && (
                        <p className="order__subtitle uk-text-left">
                          {content.extra}
                        </p>
                      )}
                      {content.price && (
                        <p className="order__subtitle uk-text-right order__price">
                          {content.price} &euro;
                        </p>
                      )}
                    </div>
                    {content.options && (
                      <div className="uk-card-footer">
                        {content.options.map((option) => {
                          return (
                            <div className="uk-grid-small" data-uk-grid>
                              <div className="uk-width-expand order__size">
                                {option.size}
                              </div>
                              <div className="order__price">
                                {option.price} &euro;
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderChicken;
