export const getWeekDay = (date) => {
  //Create an array containing each day, starting with Sunday.
  const weekdays = new Array(
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
  );
  //Use the getDay() method to get the day.
  const day = date.getDay();
  //Return the element that corresponds to that index.
  return weekdays[day];
};
