import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import BoardOne from './pages/board-one';
import BoardTwo from './pages/board-two';
import BoardThree from './pages/board-three';
import BoardFour from './pages/board-four';

export default class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/boards/board-one" component={BoardOne} />

          <Route path="/boards/board-two" component={BoardTwo} />

          <Route path="/boards/board-three" component={BoardThree} />

          <Route path="/boards/board-four" component={BoardFour} />
        </Switch>
      </Router>
    );
  }
}
