import React from 'react';

import data from '../../../data/products.json';

const Special = () => {
  return (
    <section
      className="uk-section uk-container container__special"
      id="special"
    >
      <div
        className="special-grid uk-grid-divider uk-grid-match uk-child-width-1-2@s"
        data-uk-height-match="target: .special"
        data-uk-grid
      >
        {data.specials.cols.map((special) => {
          return (
            <div>
              <h4>{special.title}</h4>
              <div
                className={`uk-card uk-card-secondary special ${
                  special.content.position === 'left'
                    ? 'special--left'
                    : 'special--right'
                }`}
              >
                <img
                  src={special.svg.src}
                  alt={special.svg.alt}
                  data-uk-svg
                  width="100"
                  className={`${
                    special.svg.position === 'left' ? 'svg--left' : 'svg--right'
                  }`}
                />
                <div className="uk-card-media-top">
                  <img src={special.image.src} alt={special.image.alt} />
                  <p
                    className={`${special.content.size === 'small' && 'small'}`}
                    dangerouslySetInnerHTML={{ __html: special.content.text }}
                  />
                </div>
                <div
                  className={`uk-card-body ${
                    special.content.position === 'left'
                      ? 'clip-left'
                      : 'clip-right'
                  }`}
                  uk-card-body
                >
                  <h3
                    className="uk-card-title"
                    dangerouslySetInnerHTML={{ __html: special.content.name }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Special;
