import React from 'react';

import data from '../../../data/products.json';

const OrderMenu = () => {
  return (
    <div
      id="menues-wrapper"
      data-uk-grid="margin: nomargin"
      data-uk-scrollspy="target: .menue; cls: uk-animation-fade; delay: 500"
      className="uk-child-width-1-3@s"
    >
      {data.orderMenu.cols.map((menu, index) => {
        const badgeNumber = index + 1;
        return (
          <div>
            <div className="uk-card order menue">
              <div className="uk-card-media-top">
                <div className="badge circle circle--hash">
                  <div className="circle__aligner">
                    <span>
                      <span className="small">#</span>
                      {badgeNumber}
                    </span>
                  </div>
                </div>

                <svg
                  className="circle circle--img"
                  viewBox="0 0 270 180"
                  width="270"
                  height="180"
                >
                  <defs>
                    <clipPath id="circleWrapper" className="circle-wrapper">
                      <use xlinkHref="#circle-shape" />
                    </clipPath>
                  </defs>
                  <circle
                    id="circle-shape"
                    className="svg-circle"
                    cx="50%"
                    cy="50%"
                    r="75"
                  />
                  <image
                    xlinkHref={menu.image.src}
                    alt={menu.image.alt}
                    width="270"
                    height="100%"
                    className="svg-img"
                  />
                </svg>
              </div>
              <div className={`uk-card-body ${menu.content.extra && 'extra'}`}>
                <h3 className="uk-card-title">{menu.content.name}</h3>
                {menu.content.sides.map((side) => {
                  return <p className="order__subtitle">{side}</p>;
                })}
                {menu.content.extra && (
                  <>
                    <span className="order__subtitle--piece">
                      {menu.content.extra}
                    </span>
                    <span className="order__subtitle--price order__price">
                      {menu.content.price} &euro;
                    </span>
                  </>
                )}
              </div>
              {menu.content.options && (
                <div className="uk-card-footer">
                  {menu.content.options.map((option) => {
                    return (
                      <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand order__size">
                          {option.size}
                        </div>
                        <div className="order__price">
                          {option.price} &euro;
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderMenu;
