import React from 'react';
import Board from '../layout/board';
import OrderMenu from '../sections/Order/Order-menu';

const BoardTwo = () => {
  return (
    <Board>
      <div>
        <h3 id="menues" className="menu-title menu-title--paddingsmall">
          Menüs
        </h3>
        <OrderMenu />
      </div>
      <div
        className="uk-flex uk-flex-between uk-child-width-auto uk-padding-large"
        data-uk-grid
      >
        <p className="uk-text-small">
          *ohne Konservierungsstoffe <br></br>
          **Alle Hähnchenprodukte sind halal.
        </p>

        <p className="uk-text-small">
          "French Fries":&nbsp;
          <a href="http://www.freepik.com">
            Designed by lifeforstock / Freepik
          </a>
        </p>
      </div>
    </Board>
  );
};

export default BoardTwo;
