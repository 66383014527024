import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <section className="uk-section" id="footer">
        <div className="uk-container">
          <footer>
            <div>
              <nav className="uk-navbar-container" data-uk-navbar>
                <div className="uk-navbar-center">
                  <ul className="uk-navbar-nav">
                    <li className="uk-parent">
                      <Link to="/imprint">Impressum</Link>
                    </li>
                    <li className="uk-parent">
                      <Link to="/data-protection">Datenschutz</Link>
                    </li>
                    <li className="uk-parent">
                      <a href="">
                        <span data-uk-icon="facebook"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
};

export default Footer;
