import React, { Component } from 'react';
import { getWeekDay } from './Header.helper';
import logoHahn from '../../../img/logo/Hahn/SVG/Hahn-Logo_fine.svg';
import heroImage from '../../../img/Hero_bild_temp.jpg';
import HeaderStopper from './Header-stopper';

const showHeadline = (state) => {
  if (state.weekDay !== 'Dienstag' && state.weekDay !== 'Donnerstag') {
    return (
      <h1 className="header-headline uk-text-center">
        Die leckersten Hähnchen weit und breit
      </h1>
    );
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekDay: getWeekDay(new Date()),
    };
  }

  render() {
    return (
      <>
        <header>
          <div
            className="uk-background-image@s uk-background-blend-color-burn uk-background-primary uk-background-cover header-wrapper"
            style={{
              backgroundImage: `url(${heroImage})`,
            }}
            data-uk-parallax="bgy: 300"
          >
            <div className="uk-overlay uk-light">
              <img
                src={logoHahn}
                alt="AFC-Hahn-Logo"
                className="uk-display-block uk-margin-auto"
              />
              {showHeadline(this.state)}
            </div>
            <div className="high-zindex uk-position-top-center uk-hidden@s">
              <HeaderStopper />
            </div>
            <div className="high-zindex uk-position-top-right uk-visible@s">
              <HeaderStopper />
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
