import React from 'react';

const GalleryItemMobile = (props) => {
  return (
    <>
      <li className="uk-width-4-5">
        <div className="uk-panel highlight">
          <img src={props.img} alt={props.alt} />
          <div className="badge polygon polygon--prize">
            <div className="polygon__aligner">
              <div>
                <span className="small">ab</span>
                <br />
                {props.price} &euro;
              </div>
            </div>
          </div>
          <div className="uk-overlay uk-position-bottom uk-overlay-primary">
            <p>{props.text}</p>
          </div>
        </div>
      </li>
    </>
  );
};

export default GalleryItemMobile;
