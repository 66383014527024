import React from 'react';

import ContactImage1 from '../../../img/Map/SVG/map.svg';
const Contact = () => {
  return (
    <div>
      <section className="uk-section" id="contact">
        <div className="uk-container">
          <div
            className="uk-grid-divider uk-child-width-expand@s uk-text-center uk-text-left@s"
            data-uk-grid
          >
            <div>
              <h2>Anfahrt</h2>
              <img width="500" height="" alt="" uk-img={ContactImage1} />
            </div>

            <div>
              <h2>Kontakt</h2>
              <address>
                American Fried Chicken
                <br />
                Griedeler Straße 47
                <br />
                35510 Butzbach
                <br />
                Tel.
                <a href="callto:06033747733" title="Einfach anrufen!">
                  &nbsp;06033 747733
                </a>
              </address>
              <p>Parkplätze vorhanden.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
