import React from 'react';

const Imprint = () => {
  return (
    <section className="uk-section uk-container" id="imprint">
      <div>
        <a
          href="/new"
          className="uk-button uk-button-primary btn-text uk-margin-left uk-width-1-6@s"
          title="Back to home"
        >
          Zurück
        </a>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          American Fried Chicken
          <br />
          Griedelerstr. 47
          <br />
          35510 Butzbach
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Pavel Leidl
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: 06033-747733
          <br />
          E-Mail: info@afc-butzbach.de
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE289257584
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <p>
          Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
        </p>
      </div>
    </section>
  );
};

export default Imprint;
